import { SyncOutlined } from '@mui/icons-material';
import { Box, Tooltip } from '@mui/material';

export const SyncedEntity = (props: {
  children?: React.ReactNode;
  isSynced: boolean | ((row: any) => boolean);
  disabled?: boolean;
}) => {
  const { children, isSynced, disabled } = props;
  return (
    <Tooltip title="This record synced from your AMS/CRM" arrow>
      <Box
        style={{
          cursor: disabled ? 'default' : 'pointer',
          display: 'flex',
          alignItems: 'center',
          gap: 4,
        }}
      >
        {isSynced && <SyncOutlined color={disabled ? 'disabled' : 'primary'} />}
        {children}
      </Box>
    </Tooltip>
  );
};
