import { CssBaseline, Snackbar, ThemeProvider } from '@mui/material';
import { StyledEngineProvider, createTheme } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
// import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import { useEffect, useRef, useState } from 'react';
import { hotjar } from 'react-hotjar';
import { RouterProvider } from 'react-router-dom';
import { StatsigProvider } from 'statsig-react';

import DialogHost from '@/components/DialogHost';
import ErrorBoundary from '@/components/ErrorBoundary';
import LoadingMask from '@/components/atoms/LoadingMask';
import LoadingProvider from '@/contexts/LoadingContext';
import UiStateProvider from '@/contexts/UIStateProvider';
import API from '@/services/API';
import appearance from '@/services/appearance';
import { useAuth } from '@/services/useAuth';
import { useAccountStore, useFeVersionStore, useRoleStore } from '@/store';
import {
  ErrorResponse,
  GlobalStateCodes,
  Roles,
  States,
  UserCheck,
} from '@/types';
import Bar from '@/views/Bar';
import Router from '@/views/Router';

interface userAccount {
  account_id: string;
  role_id: Roles;
  account: {
    str_id: string;
    name: string;
    mode: string;
    comp_grids_enabled: boolean;
    accounting_transactions_enabled: boolean;
    white_label_mode: boolean;
    logo_url: string;
    uid: string;
  };
}

interface UserLogin {
  statusText: string;
  response: {
    userOnboardingNeeded: boolean;
    userEmail?: string;
    accountOnboardingNeeded: boolean;
    userOverallState: States;
    userAccounts: userAccount[];
  };
  error: {
    message: string;
  };
  type: string;
}
interface DialogState {
  open: boolean;
  email?: string;
}

interface SnackbarState {
  autoHideDuration: number;
  message: string;
  open: boolean;
}

interface AppState {
  ready: boolean;
  performingAction: boolean;
  userData: any;
  roles: any[];
  signUpDialog: DialogState;
  signInDialog: DialogState;
  emailVerificationDialog: DialogState;
  snackbar: SnackbarState;
}

interface User {
  email: string;
  uid: string;
  emailVerified: boolean;
}

const baseTheme = appearance.defaultTheme;

const theme = createTheme({
  ...baseTheme,
});

const initialState: AppState = {
  ready: false,
  performingAction: false,
  userData: null,
  roles: [],
  signUpDialog: {
    open: false,
  },
  signInDialog: {
    open: false,
  },
  emailVerificationDialog: {
    open: false,
  },
  snackbar: {
    autoHideDuration: 0,
    message: '',
    open: false,
  },
};

const App = () => {
  const [user, setUser] = useState<User | null>(null);
  const [authError, setAuthError] = useState<string | null>(null);
  const [loginEmail, setLoginEmail] = useState<string>('');

  // Custom hook for handle authentication
  const { signOut } = useAuth(setUser, setLoginEmail, setAuthError);
  const statsigKey = process.env.REACT_APP_STATSIG_API_KEY || '';
  const environment = process.env.REACT_APP_ENVIRONMENT || '';
  const [state, setState] = useState<AppState>(initialState);
  const [userState, setUserState] = useState<UserCheck | ErrorResponse>();
  const [showUpdateMessage, setShowUpdateMessage] = useState(false);
  const [hasSignedOut, setHasSignedOut] = useState(false);

  const { setUserRole } = useRoleStore();
  const { feVersion, setFeVersion } = useFeVersionStore();
  const { selectedAccount, setSelectedAccount } = useAccountStore();

  // const tawkMessengerRef = useRef<{
  //   maximize: () => void;
  //   hideWidget: () => void;
  //   visitor: ({}) => void;
  //   setAttributes: ({}) => void;
  // } | null>(null);

  const loginPoster = API.getMutation('login', 'POST');
  const authHandlerPoster = API.getMutation('auth_handler', 'POST');

  useEffect(() => {
    setTimeout(() => {
      setShowUpdateMessage(false);
    }, 5000);
  }, [showUpdateMessage]);

  useEffect(() => {
    if (
      feVersion &&
      feVersion.stateCode === GlobalStateCodes.FE_OUT_OF_DATE &&
      !showUpdateMessage
    ) {
      if (feVersion.message !== null) {
        window.confirm(feVersion.message);
      }
      setFeVersion({
        stateCode: null,
        message: null,
      });
      setShowUpdateMessage(true);
    }
    if (
      feVersion &&
      feVersion.stateCode === GlobalStateCodes.FE_INCOMPATIBLE &&
      !showUpdateMessage
    ) {
      if (feVersion.message !== null) {
        window.confirm(feVersion.message);
        window.location.reload();
      }
      setFeVersion({
        stateCode: null,
        message: null,
      });
      setShowUpdateMessage(true);
    }
  }, [feVersion]);

  // Reload all appication tabs after a logout
  useEffect(() => {
    window.addEventListener('storage', (event) => {
      if (event.key === 'loggedOut') {
        // If the loggedOut value was changed in localStorage, reload the page
        window.location.reload();
      }
    });
  }, []);

  const setUserAndAccount = (userLogin: UserLogin) => {
    if (!selectedAccount || Object.keys(selectedAccount).length === 0) {
      const account = userLogin.response.userAccounts[0].account;
      setSelectedAccount({
        accountId: account.str_id,
        accountName: account.name,
        accountMode: account.mode,
        compGridsEnabled: account.comp_grids_enabled,
        accountingTransactionsEnabled: account.accounting_transactions_enabled,
        uid: account.uid,
        whiteLabelMode: account.white_label_mode,
        logoUrl: account.logo_url,
      });
    }
    setUserState({
      userOverallState: userLogin.response.userOverallState,
      userEmail: userLogin.response.userEmail,
      userOnboardingNeeded: userLogin.response.userOnboardingNeeded,
      accountOnboardingNeeded: userLogin.response.accountOnboardingNeeded,
      userAccounts: userLogin.response.userAccounts,
    });
    setState((prevState) => ({
      ...prevState,
      ready: true,
      user: user,
    }));
  };

  const handleUserLogin = async (user: User) => {
    // If 'emailVerified' is not present, assume it's an impersonated user and consider it as verified.
    const isUserEmailVerified = Object.prototype.hasOwnProperty.call(
      user,
      'emailVerified'
    )
      ? user.emailVerified
      : true;

    const MAX_RETRIES = 10;
    let tries = MAX_RETRIES;
    while (tries > 0) {
      try {
        const userLogin: UserLogin = await loginPoster.mutateAsync(user);
        if (userLogin.statusText !== 'OK') {
          openSnackbar(userLogin.error.message);
          throw new Error(userLogin.error.message);
        }
        if (
          userLogin.response.userAccounts[0].role_id !== Roles.ACCOUNT_ADMIN &&
          !isUserEmailVerified
        ) {
          openDialog('emailVerificationDialog');
          break;
        }
        setUserAndAccount(userLogin);
        break;
      } catch (error) {
        console.error(error);
        tries--;
        if (tries === 0) break;
        await new Promise((resolve) =>
          setTimeout(
            resolve,
            Math.min(Math.pow(2, MAX_RETRIES - tries), 30) * 1000
          )
        );
      }
    }
    if (tries <= 0) {
      openSnackbar(
        'Error logging in, please try again later or contact support@fintary.com',
        30 * 1000
      );
    }
  };

  useEffect(() => {
    const isLoggedIn = user !== null;

    if (authError) {
      setState((prevState) => ({
        ...prevState,
        ready: true,
        user: null,
        roles: [],
      }));
      openSnackbar(authError);
      return;
    }

    const userCheck = async () => {
      const impUser = JSON.parse(
        localStorage.getItem('customLoginUser') ?? '{}'
      );

      try {
        if (isLoggedIn && Object.keys(impUser).length > 0) {
          impUser.isImpUser = true;
          await handleUserLogin(impUser);
        } else if (isLoggedIn && user) {
          await handleUserLogin(user);
        }
      } catch (error) {
        console.error('An error occurred:', error);
        Sentry.captureException(error);
      }
    };

    if (isLoggedIn && user) {
      if (process.env.NODE_ENV === 'production') {
        hotjar.initialize({ id: 3472012, sv: 6 });
        setTimeout(() => {
          if (hotjar.initialized()) {
            hotjar.identify(user.uid, {
              userUid: user.uid,
            });
          }
        }, 2000);
      }
    } else {
      setState((prevState) => ({
        ...prevState,
        ready: true,
        performingAction: false,
      }));
    }
    userCheck();
  }, [user, authError]);

  const openSnackbar = (
    message: string,
    autoHideDuration: number = 8000
  ): void => {
    setState((prevState) => ({
      ...prevState,
      snackbar: {
        autoHideDuration,
        message,
        open: true,
      },
    }));
  };

  // Hook for saving selected user account
  useEffect(() => {
    if (selectedAccount) {
      if (userState) {
        // @ts-ignore
        const userAccount = userState.userAccounts?.find(
          (account) => account.account_id === selectedAccount.accountId
        );
        if (userAccount) {
          localStorage.setItem(
            'userRole',
            JSON.stringify(userAccount.role_id ?? '')
          );
          setUserRole(userAccount.role_id);
        }
      }
      localStorage.setItem(
        'selectedAccount',
        JSON.stringify(selectedAccount ?? '')
      );
    }
  }, [selectedAccount, userState]);

  const openDialog = (dialogId: keyof AppState): void => {
    const dialog = state[dialogId];

    if (!dialog || dialog.open === undefined || null) {
      return;
    }

    dialog.open = true;

    setState((prevState) => ({
      ...prevState,
      dialog,
    }));
  };

  const closeDialog = (dialogId: keyof AppState): void => {
    const dialog = state[dialogId];

    if (!dialog || dialog.open === undefined || null) {
      return;
    }

    dialog.open = false;

    setState((prevState) => ({
      ...prevState,
      dialog,
    }));
  };

  const closeDialogCallback = (
    dialogId: keyof AppState,
    callback?: () => void
  ) => {
    closeDialog(dialogId);
    if (callback && typeof callback === 'function') {
      callback();
    }
  };

  // Sign In or Sign Up dialog based on query params
  const queryParams = new URLSearchParams(window.location.search);
  const loginType = queryParams.get('login-type');
  const userLoginEmail = queryParams.get('email');

  const issuer = queryParams.get('issuer');
  const token = queryParams.get('token');
  const role_name = queryParams.get('role_name');

  useEffect(() => {
    const verifyToken = async () => {
      const response = await authHandlerPoster.mutateAsync({
        issuer,
        token,
      });
      if (response.valid) {
        setUser({
          ...response.user,
          sso: true,
          role_assigned: role_name,
        });
        localStorage.setItem('sso-token', token ? `sso:${token}` : '');
      } else {
        openSnackbar('Unable to log in, redirecting to login page');
        setTimeout(() => {
          if (issuer === 'transglobal')
            window.location.href = `https://hubs.transglobalus.com/_hcms/mem/login?redirect_url=${window.location.href}`;
        }, 2000);
      }
    };

    if (issuer && token) {
      verifyToken();
    }
  }, [issuer, token]);

  useEffect(() => {
    const signOutWhenInvitingUser = () => {
      if (!user) setHasSignedOut(true);
      if (user && !hasSignedOut) {
        signOut(() => {
          localStorage.clear();
          setSelectedAccount(null);
          setState((prevState) => ({
            ...prevState,
            ready: true,
            performingAction: false,
            userData: null,
            roles: [],
          }));
          openSnackbar('Signed out');
          setHasSignedOut(true);
        });
      }
    };

    const handleDialogOpen = (dialogType) => {
      setLoginEmail(userLoginEmail || '');
      setTimeout(() => {
        signOutWhenInvitingUser();
        openDialog(dialogType);
      }, 1000);
    };

    if (loginType === 'signUp') {
      handleDialogOpen('signUpDialog');
    }
    if (loginType === 'signIn') {
      handleDialogOpen('signInDialog');
    }
  }, [user, hasSignedOut, loginType, userLoginEmail]);

  const closeSnackbar = (clearMessage: boolean = false): void => {
    setState((prevState) => ({
      ...prevState,
      snackbar: {
        autoHideDuration: 10,
        message: clearMessage ? '' : prevState.snackbar.message,
        open: false,
      },
    }));
  };

  // useEffect(() => {
  //   if (
  //     tawkMessengerRef.current &&
  //     typeof tawkMessengerRef.current.setAttributes === 'function' &&
  //     user
  //   ) {
  //     try {
  //       tawkMessengerRef.current.setAttributes({
  //         name: user?.email,
  //         email: user?.email,
  //       });
  //     } catch (error) {
  //       // Do nothing
  //     }
  //   }
  // }, [user, tawkMessengerRef.current]);

  useEffect(() => {
    if (user && user.uid) {
      Sentry.setUser({ id: user.uid, email: user.email });
    }
  });

  const {
    ready,
    performingAction,
    roles,
    signUpDialog,
    signInDialog,
    emailVerificationDialog,
    snackbar,
  } = state;
  const statsigUser = {
    userID: selectedAccount?.accountId || '',
    email: user?.email || '',
  };
  const userInfo = {
    name: selectedAccount?.accountName ?? '',
    email: user?.email ?? '',
  };
  return (
    <ErrorBoundary user={userInfo}>
      <LoadingProvider>
        <StatsigProvider
          sdkKey={statsigKey}
          options={{
            environment: { tier: environment },
          }}
          waitForInitialization={true}
          user={statsigUser}
        >
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <UiStateProvider>
                <CssBaseline />
                {ready && (
                  <>
                    <RouterProvider
                      router={Router({
                        user,
                        roles,
                        userState,
                        // @ts-ignore
                        openSnackbar: openSnackbar,
                        bar: (
                          <Bar
                            openSnackbar={openSnackbar}
                            performingAction={performingAction}
                            user={user}
                            userState={userState}
                            roles={roles}
                            onSignUpClick={() => openDialog('signUpDialog')}
                            onSignInClick={() => openDialog('signInDialog')}
                            onSignOutClick={() => {
                              setState((prevState) => ({
                                ...prevState,
                                performingAction: true,
                              }));
                              signOut(() => {
                                localStorage.clear();
                                setSelectedAccount(null);
                                setState((prevState) => ({
                                  ...prevState,
                                  ready: true,
                                  performingAction: false,
                                  userData: null,
                                  roles: [],
                                }));
                                openSnackbar('Signed out');
                                localStorage.setItem(
                                  'loggedOut',
                                  Date.now().toString()
                                );
                              });
                            }}
                            onHelpClick={() => {
                              // if (tawkMessengerRef.current) {
                              //   tawkMessengerRef.current.maximize();
                              // }
                              alert('Please contact help@fintary.com');
                            }}
                          />
                        ),
                      })}
                    />
                    <DialogHost
                      theme={theme}
                      user={user}
                      openSnackbar={openSnackbar}
                      dialogs={{
                        signUpDialog: {
                          dialogProps: {
                            open: signUpDialog.open,
                            email: loginEmail,
                            onClose: (callback) => {
                              closeDialogCallback('signUpDialog', callback);
                            },
                          },
                        },
                        signInDialog: {
                          dialogProps: {
                            open: signInDialog.open,
                            email: loginEmail,
                            onClose: (callback) => {
                              closeDialogCallback('signInDialog', callback);
                            },
                          },
                        },
                        emailVerificationDialog: {
                          dialogProps: {
                            open: emailVerificationDialog.open,
                            email: loginEmail,
                            onClose: (callback) => {
                              closeDialogCallback(
                                'emailVerificationDialog',
                                callback
                              );
                            },
                          },
                        },
                      }}
                    />
                    {/* @ts-ignore */}
                    <Snackbar
                      autoHideDuration={snackbar.autoHideDuration}
                      message={
                        typeof snackbar.message === 'string'
                          ? snackbar.message
                          : ''
                      }
                      open={snackbar.open}
                      // @ts-ignore
                      onClose={closeSnackbar}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                    >
                      {/* @ts-ignore */}
                      {typeof snackbar.message !== 'string'
                        ? snackbar.message
                        : null}
                    </Snackbar>
                    {/* <TawkMessengerReact
                      propertyId="6626e6281ec1082f04e5ad21"
                      widgetId="1hs3v63th"
                      ref={tawkMessengerRef}
                      onLoad={() => {
                        if (tawkMessengerRef.current) {
                          tawkMessengerRef.current.hideWidget();
                        }
                      }}
                      customStyle={{
                        zIndex: 1200,
                        visibility: {
                          desktop: {
                            position: 'br',
                          },
                          mobile: {
                            position: 'br',
                          },
                        },
                      }}
                    /> */}
                  </>
                )}
              </UiStateProvider>
              <LoadingMask />
            </ThemeProvider>
          </StyledEngineProvider>
        </StatsigProvider>
      </LoadingProvider>
    </ErrorBoundary>
  );
};

export default App;
