import { Alert, useMediaQuery } from '@mui/material';
import { AccountIds } from 'common/constants';
import { Navigate, useOutletContext } from 'react-router-dom';

import { SyncedEntity } from '@/common/SyncedEntity';
import { SyncEndAdornment } from '@/common/SyncEndAdornment';
import EnhancedDataView from '@/components/organisms/EnhancedDataView';
import { ReconciliationConfirmProvider } from '@/contexts/ReconciliationConfirmProvider';
import useSyncedFields from '@/contexts/useSyncedFields';
import API from '@/services/API';
import Reports from '@/services/Reports';
import { useAccountStore, useRoleStore } from '@/store';
import { Roles } from '@/types';

const exportOptions = [
  {
    id: 'export',
    label: 'Export',
    options: {},
  },
  // If we want to enable the export producer view, we can add the following
  // {
  //   id: 'export-producer-view',
  //   label: 'Export producer view',
  //   options: { producer_view: true },
  // },
];

const PolicyDataView = ({ reportId = null }) => {
  // @ts-ignore
  const { openSnackbar } = useOutletContext() ?? {};

  const { data: settingsData, isFetched: isFetchedUserSettings } =
    API.getUser();
  const { data: accountSettings, isFetched: isFetchedAccountSettings } =
    API.getBasicQuery(`accounts/settings`);
  const { selectedAccount } = useAccountStore();
  const { userRole } = useRoleStore();
  const mode = selectedAccount?.accountMode;
  const syncPolicy = API.getMutation(
    'data_processing/sync/nowcerts/policy',
    'POST'
  );

  const isTrailStoneAccount =
    AccountIds.TRAILSTONE === selectedAccount?.accountId;
  const reports = new Reports(mode, { account_id: selectedAccount?.accountId });
  const isMobile = useMediaQuery('(max-width:600px)');

  const pageSettingFields = isMobile
    ? accountSettings?.pages_settings?.policies?.outstandingMobileFields
    : accountSettings?.pages_settings?.policies?.fields;
  const newFields = (pageSettingFields ?? []).reduce((acc, cur) => {
    acc[cur] = {
      ...reports.fields[cur],
    };
    return acc;
  }, {});
  if (Object.keys(newFields).length > 0) reports.fields = newFields;

  if (accountSettings?.pages_settings?.policies?.page_label) {
    reports.label = accountSettings?.pages_settings?.policies?.page_label;
  }

  if (
    isFetchedAccountSettings &&
    accountSettings?.pages_settings?.policies?.show_page === false
  ) {
    return (
      // TODO: Remove navigate after figuring out how to handle this in router
      <Navigate to="/settings" />
      // <Box sx={{ textAlign: 'center', mt: 6, width: '100%' }}>
      //   <Typography variant="h5">No results</Typography>
      // </Box>
    );
  }
  const { syncedFields, isSyncedField } = useSyncedFields('report_data');

  for (const key in reports.fields) {
    const field = reports.fields[key];
    field.readOnly =
      field.readOnly ||
      ((data) => {
        if (syncedFields?.includes(field.id)) {
          return isSyncedField(data, field.id, data.config);
        }
        return false;
      });
    field.endAdornment = (data, field, setNewData) => (
      <SyncEndAdornment
        syncedFields={syncedFields}
        syncId={data?.sync_id}
        fieldId={field?.id}
        data={data}
        onChange={(newOverrideFields) => {
          setNewData({
            ...data,
            config: {
              ...(data.config || {}),
              overrideFields: newOverrideFields,
            },
          });
        }}
      />
    );
  }

  const onBulkSync = async (params) => {
    await syncPolicy.mutateAsync(params);
    openSnackbar(<Alert severity="success">Sync successfully</Alert>);
  };

  return settingsData && isFetchedUserSettings && isFetchedAccountSettings ? (
    <ReconciliationConfirmProvider mode={mode}>
      <EnhancedDataView
        reportId={reportId}
        dataSpec={reports}
        exportOptions={exportOptions}
        outstandingMobileFields={
          accountSettings?.pages_settings?.policies?.outstandingMobileFields
        }
        enableSaves
        showTotals
        onBulkSync={
          isTrailStoneAccount ? (ids) => onBulkSync({ ids }) : undefined
        }
        actions={[
          {
            type: isTrailStoneAccount ? 'iconButton' : 'icon',
            label: 'Sync',
            icon: (
              <SyncedEntity
                isSynced={(row) => !!row.sync_id}
                disabled={!isTrailStoneAccount}
              />
            ),
            onClick: isTrailStoneAccount
              ? async (row, e) => {
                  e?.stopPropagation();
                  e?.preventDefault();
                  if (isTrailStoneAccount) {
                    await onBulkSync({ syncId: row.sync_id });
                  }
                }
              : () => {},
          },
        ]}
        actionsEnabled={(row) => {
          return !!row.sync_id;
        }}
        // TODO (frank.santillan): Move to settings after we migrate reconciliation / commissions / policies to pages_settings.
        readOnly={userRole === Roles.PRODUCER}
      />
    </ReconciliationConfirmProvider>
  ) : null;
};

export default PolicyDataView;
