import { Add, Download, ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { LoadingContext } from 'contexts/LoadingContext';
import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useOutletContext, useSearchParams } from 'react-router-dom';

import DataBulkAdd from '@/components/DataBulkAdd';
import DataBulkAddCompGrids from '@/components/DataBulkAdd/DataBulkAddCompGrids';
import DataForm from '@/components/DataForm';
import LoadingCircle from '@/components/atoms/LoadingCircle';
import EnhancedTable from '@/components/molecules/EnhancedTable';
import SearchBox from '@/components/molecules/SearchBox';
import SplitButton from '@/components/molecules/SplitButton';
import { auth } from '@/firebase';
import API from '@/services/API';
import { exportToCsv } from '@/services/helpers';
import { useRoleStore } from '@/store';
import { Roles } from '@/types';

const DataView = ({
  dataDesc,
  formModeOnly = false,
  viewOnly = false,
  hideExport,
  refresh,
  extraActions,
  dataCallback,
  sx = {},
  enableAutoRefetch = true,
  embed = false,
  readOnly = false,
  openSnackbar: openSnackbarProp = null,
  exportOptions = [],
  // Use them to trigger a callback in the partent component with any custom export logic
  customExport = false,
  customExportCallback = () => {},
  handleCustomExportOptions,
  rowKey = '',
  setSelectedData = (a) => {},
  onDelete = null,
  onBulkEdit = () => {},
  customHeaderActions = false,
  enablePagination = false,
  customQueryParams = [],
  headingOffset = 98,
}) => {
  // TODO: Find a better way to make this component to work with Onboarding, openSnackbar context is not available in Onboarding
  // @ts-ignore
  let openSnackbar = (_) => {};
  if (!openSnackbarProp) {
    const outletContext = useOutletContext();
    if (outletContext?.openSnackbar) {
      openSnackbar = outletContext.openSnackbar;
    } else {
      openSnackbar = () => {}; // No-op. Not available and not needed during onboarding.
    }
  } else {
    openSnackbar = openSnackbarProp;
  }
  const isMobile = useMediaQuery('(max-width:600px)');
  const [searchParams, setSearchParams] = useSearchParams({});
  const mode = searchParams.get('m') ?? 'list';
  const embedMode = searchParams.get('em') ?? 'list';
  const [newData, setNewData] = useState({});
  const [oldData, setOldData] = useState({});

  // Can put editingData and originData in the same state
  const [editingData, setEditingData] = useState({});
  const [originData, setOriginData] = useState({});

  const [isDownloading, setIsDownloading] = useState(false);
  const [qcExpanded, setQcExpanded] = useState(false);
  const [selectedExportOptions, setSelectedExportOptions] = useState({});
  const [page, setPage] = useState(0);
  const [orderBy, setOrderBy] = useState('created_at');
  const [order, setOrder] = useState('desc');
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const { userRole } = useRoleStore();

  let additionalQueryParams = '';
  const idParam = searchParams.get('id');
  if (idParam) {
    setSelectedExportOptions;
    // additionalQueryParams += `&id=${idParam}`;
  }

  const [embededId, setEmbededId] = useState(null);

  Array.from(searchParams.entries()).forEach(([key, value]) => {
    // console.log(key, value);
    additionalQueryParams += `&${key}=${value}`;
  });

  if (enablePagination) {
    additionalQueryParams += `&page=${page}&limit=${rowsPerPage}&orderBy=${orderBy}&sort=${order}`;
  }
  if (dataDesc.queryChips && searchParams.get('qc')) {
    const chipQuery = dataDesc.queryChips[searchParams.get('qc')]?.query ?? {};
    Object.entries(chipQuery).forEach(([k, v]) => {
      if (v instanceof Array) {
        v.forEach((e) => {
          additionalQueryParams += `&${k}=${encodeURIComponent(e)}`;
        });
      } else {
        additionalQueryParams += `&${k}=${encodeURIComponent(v)}`;
      }
    });
  }
  customQueryParams.forEach((param) => {
    if (searchParams.get(param))
      additionalQueryParams += `&${param}=${searchParams.get(param)}`;
  });

  useEffect(() => {
    const params = new URLSearchParams(additionalQueryParams);

    params.set('page', page.toString());
    params.set('limit', rowsPerPage.toString());

    additionalQueryParams = `?${params.toString()}`;
  }, [page, rowsPerPage]);

  let { isLoading, data, refetch } = API.getBasicQuery(
    dataDesc.table,
    additionalQueryParams,
    enableAutoRefetch
  );

  let count = 0;
  if (enablePagination) {
    count = data?.count;
    data = data?.data;
  }

  useEffect(() => {
    if (!isEqual(newData, editingData) && isEqual(newData, originData)) {
      setNewData({ ...newData, ...editingData });
    }
  }, [originData]);

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const { setLoadingConfig } = useContext(LoadingContext);

  const updateSearchParams = (kvMap) =>
    setSearchParams((prev) => {
      Object.entries(kvMap).forEach(([k, v]) => {
        if ([undefined, null, ''].includes(v)) {
          prev.delete(k);
        } else {
          prev.set(k, v);
        }
      });
      return prev;
    });

  useEffect(() => {
    let _data;
    let _count;

    if (enablePagination) {
      _data = data?.data;
      _count = data?.count;
    } else {
      _data = data;
    }

    if (formModeOnly) {
      const defaultData =
        _data ||
        dataDesc.fields
          .filter((field) => field.default !== undefined)
          .reduce((acc, cur) => ({ ...acc, [cur.id]: cur.default }), {});
      setNewData(defaultData);
      setOldData(JSON.parse(JSON.stringify(defaultData)));
    }

    if (_count !== undefined) {
      count = _count;
    }
  }, [data, dataDesc.fields, formModeOnly, enablePagination]);

  useEffect(() => {
    if (
      mode === 'edit' &&
      idParam &&
      Array.isArray(data) &&
      data.length === 1
    ) {
      setNewData(data[0]);
      setOriginData(data[0]);
      setOldData(JSON.parse(JSON.stringify(data[0])));
    }
  }, [data, mode, idParam]);

  useEffect(() => {
    if (refresh) {
      setTimeout(refetch, 100);
    }
  }, [refresh, refetch]);

  const poster = API.getMutation(dataDesc.table, 'POST');
  // @ts-ignore
  const posterBulk = API.getMutation(`${dataDesc.table}/bulk_add`, 'POST');
  const patcher = API.getMutation(dataDesc.table, 'PATCH');
  const deleter = API.getMutation(dataDesc.table, 'DELETE');

  /**
   * Download CSV
   */
  const downloadCsvFn = useCallback(async () => {
    const idToken = (await auth.currentUser?.getIdToken(true)) || '';
    await exportToCsv(
      {},
      {
        idToken,
        endpoint: dataDesc.table,
        exportOptions: selectedExportOptions,
      }
    );
  }, [dataDesc.table, selectedExportOptions]);

  useEffect(() => {
    const doDownload = async () => {
      try {
        await downloadCsvFn();
      } catch (e) {
        console.error(e);
        openSnackbar(<Alert severity="error">Error exporting data</Alert>);
      } finally {
        setIsDownloading(false);
        setLoadingConfig({
          loading: false,
        });
      }
    };
    if (isDownloading) {
      doDownload();
    }
  }, [downloadCsvFn, isDownloading, setLoadingConfig]);

  let queryFields = dataDesc.fields
    .flat()
    .filter((field) => field.queryable && field.type !== 'boolean')
    .map((field) => field.id);
  queryFields =
    queryFields.length > 0
      ? queryFields
      : dataDesc.fields
          .filter((field) => field.type !== 'boolean')
          .map((field) => field.id);

  const dataFiltered = Array.isArray(data)
    ? data?.filter((datum) => {
        let query = searchParams.get('q');
        if (enablePagination) query = null;
        if (!query) return true;
        return Object.entries(datum)
          .filter(([k, v]) => queryFields.includes(k))
          .some(([key, value]) =>
            (typeof value === 'object'
              ? JSON.stringify(value ?? {})
              : (value ?? '').toString()
            )
              .toLowerCase()
              .includes(query.trim().toLowerCase())
          );
      })
    : data;
  const DataFormer = (
    <DataForm
      dataDesc={dataDesc}
      fields={dataDesc.fields.filter((field) =>
        field.condition ? field.condition(newData) : true
      )}
      newData={newData}
      oldData={oldData}
      setNewData={(data) => {
        setNewData(data);
        setEditingData(data);
      }}
      onCancel={() => {
        setNewData({});
        setEditingData({});
        updateSearchParams(embed ? { em: null } : { m: null, id: null });
        embed && setEmbededId(null);
      }}
      onSave={async () => {
        let dbData = null;
        const dataDescFields = dataDesc.fields.flat();
        const normalizedNewData = Object.fromEntries(
          Object.entries(newData).map(([key, value]) => [
            key,
            dataDescFields.find((field) => field.id === key)?.normalizer
              ? dataDescFields
                  .find((field) => field.id === key)
                  .normalizer(value)
              : value,
          ])
        );
        if (normalizedNewData.id) {
          dbData = await patcher.mutateAsync(normalizedNewData);
        } else {
          dbData = await poster.mutateAsync(normalizedNewData);
        }
        if (dbData.error) {
          openSnackbar(<Alert severity="error">{dbData.error}</Alert>);
          return;
        }
        if (dataCallback) dataCallback(dbData);
        setNewData({});
        setEditingData({});
        updateSearchParams(embed ? { em: null } : { m: null, id: null });
        embed && setEmbededId(null);
        setTimeout(refetch, 250);
        return dbData;
      }}
      onDelete={async () => {
        deleter.mutate({ ids: [newData.id], strId: newData.str_id ?? '' });
        setNewData({});
        setEditingData({});
        updateSearchParams(embed ? { em: null } : { m: null, id: null });
        embed && setEmbededId(null);
        setTimeout(refetch, 250);
      }}
      validateData={dataDesc.validateData}
      formModeOnly={formModeOnly}
      embed={embed}
      readOnly={readOnly}
    />
  );

  const DataBulkAdder = (
    <DataBulkAdd
      fields={dataDesc.fields
        .flat()
        .filter(
          (field) =>
            [undefined, 'select', 'dynamic-select', 'boolean', 'date'].includes(
              field.type
            ) &&
            field.access !== 'admin' &&
            !field.readOnly &&
            !field.bulkAddUnsupported
        )}
      onCancel={() => {
        updateSearchParams(embed ? { em: null } : { m: null });
      }}
      onSave={async (jsonEntities) => {
        const res = await posterBulk.mutateAsync(jsonEntities);
        if (res.statusText === 'ok') {
          openSnackbar(
            <Alert severity="success">{`Added ${res?.stats?.current_length} records`}</Alert>
          );
          updateSearchParams(embed ? { em: null } : { m: null });
          setTimeout(refetch, 250);
        } else {
          openSnackbar(<Alert severity="error">Error bulk adding data</Alert>);
        }
      }}
    />
  );
  const DataBulkAdderCompGrids = (
    <DataBulkAddCompGrids
      fields={dataDesc.fields
        .flat()
        .filter(
          (field) =>
            [undefined, 'select', 'dynamic-select', 'boolean', 'date'].includes(
              field.type
            ) &&
            field.access !== 'admin' &&
            !field.readOnly &&
            !field.bulkAddUnsupported
        )}
      onCancel={() => {
        updateSearchParams(embed ? { em: null } : { m: null });
      }}
      onSave={async (jsonEntities) => {
        const res = await posterBulk.mutateAsync(jsonEntities);
        if (res.statusText === 'ok') {
          openSnackbar(
            <Alert severity="success">{`Added ${res?.stats?.current_length} records`}</Alert>
          );
          updateSearchParams(embed ? { em: null } : { m: null });
          setTimeout(refetch, 250);
        } else {
          openSnackbar(
            <Alert severity="error">{`Error bulk adding records ${
              res.error ? ` (${res.error}})` : ''
            }`}</Alert>
          );
        }
      }}
    />
  );

  const onExport = useCallback(
    (options) => {
      setSelectedExportOptions(options);
      setIsDownloading(true);
      setLoadingConfig({
        loading: true,
        message: 'Exporting...',
      });
    },
    [setLoadingConfig, setSelectedExportOptions]
  );

  useEffect(() => {
    if (customExport) {
      handleCustomExportOptions(onExport);
    }
  }, [handleCustomExportOptions, customExport, onExport]);

  return (
    <Box
      sx={{
        ...{
          width: formModeOnly
            ? 'inherit'
            : `calc(100vw - ${isMobile ? '0px' : '200px'})`,
          overflowY: embed ? 'auto' : 'scroll',
        },
        ...sx,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          pt: embed ? 0 : 2,
          px: 2,
          pb: embed ? 0 : 1,
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">{dataDesc.label}</Typography>
          {mode !== 'add' && !formModeOnly && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {(idParam || embededId) && (
                <Chip
                  label={!embed ? idParam : embededId}
                  sx={{ mr: 1 }}
                  onDelete={() => {
                    setNewData({});
                    updateSearchParams(
                      embed ? { em: null } : { m: null, id: null }
                    );
                    embed && setEmbededId(null);
                  }}
                  // variant="outlined"
                  color="primary"
                />
              )}
              <SearchBox />
            </Box>
          )}
        </Box>
        {!['add', 'bulkAdd'].includes(mode) && !formModeOnly && (
          <Box className="mt-2 flex justify-between items-start">
            <Box id="filter-chips">
              {Object.keys(dataDesc?.filterConfigs ?? {})?.length > 0
                ? Object.entries(dataDesc?.filterConfigs ?? {}).map(
                    ([filterId, filterConfig]) => (
                      <FormControl sx={{ m: 1, minWidth: 120 }} key={filterId}>
                        <InputLabel>{filterConfig.label}</InputLabel>
                        <Select
                          value={
                            searchParams.get(filterId) ??
                            (filterConfig.noAll ? '' : 'all')
                          }
                          label={filterConfig.label}
                          onChange={(e, v) => {
                            setSearchParams((prev) => {
                              if (
                                ['all', 0, '0'].includes(`${e.target.value}`)
                              ) {
                                prev.delete(filterId);
                              } else {
                                prev.set(filterId, `${e.target.value}`);
                              }
                              return prev;
                            });
                          }}
                        >
                          {filterConfig.noAll ? (
                            <MenuItem value="">{''}</MenuItem>
                          ) : (
                            <MenuItem value="all">
                              {`All (${Object.keys(filterConfig.options).length})`}
                            </MenuItem>
                          )}
                          {Object.values(filterConfig.options ?? {})
                            .sort((a, b) => a.label.localeCompare(b.label))
                            .map((option) => (
                              <MenuItem value={`${option.id}`} key={option.id}>
                                {option.label}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    )
                  )
                : null}

              {Object.values(dataDesc.queryChips ?? {})
                ?.filter((val, i) => qcExpanded || i < 10)
                .map((chip) => (
                  <Chip
                    key={chip.id}
                    label={chip.label}
                    onClick={() => {
                      // Restart pagination when changing query chips
                      setPage(0);
                      setSearchParams((prev) => {
                        if (['all', 0, '0'].includes(chip.id)) {
                          prev.delete('qc');
                        } else {
                          prev.set('qc', chip.id);
                        }
                        return prev;
                      });
                    }}
                    sx={{
                      mr: 0.5,
                      my:
                        Object.keys(dataDesc?.filterConfigs ?? {})?.length > 0
                          ? 1.5
                          : 0.25,
                      cursor: 'pointer',
                    }}
                    color={
                      searchParams.get('qc') === chip.id ||
                      (!searchParams.get('qc') &&
                        ['all', 0, '0'].includes(chip.id))
                        ? 'primary'
                        : 'default'
                    }
                    variant={
                      searchParams.get('qc') === chip.id ||
                      (!searchParams.get('qc') &&
                        ['all', 0, '0'].includes(chip.id))
                        ? 'filled'
                        : 'outlined'
                    }
                  />
                ))}
              {dataDesc.queryChipsType !== 'select' &&
                Object.values(dataDesc.queryChips ?? {})?.length > 10 && (
                  <IconButton onClick={() => setQcExpanded(!qcExpanded)}>
                    {qcExpanded ? (
                      <ExpandLess sx={{ width: 16, height: 16 }} />
                    ) : (
                      <ExpandMore sx={{ width: 16, height: 16 }} />
                    )}
                  </IconButton>
                )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {extraActions ? <Box sx={{ ml: 1 }}>{extraActions}</Box> : null}
                {!(viewOnly || formModeOnly) && (
                  <SplitButton
                    startIcon={<Add />}
                    options={
                      dataDesc.bulkAdd &&
                      [Roles.ACCOUNT_ADMIN, Roles.DATA_SPECIALIST].includes(
                        userRole
                      )
                        ? [
                            {
                              id: 'add',
                              label: 'Add',
                              onClick: () => {
                                setNewData({});
                                updateSearchParams({ m: 'add' });
                              },
                            },
                            {
                              id: 'bulkAdd',
                              label: 'Bulk add',
                              onClick: () =>
                                updateSearchParams({ m: 'bulkAdd' }),
                            },
                          ]
                        : [
                            {
                              id: 'add',
                              label: 'Add',
                              onClick: () => {
                                setNewData({});
                                updateSearchParams({ m: 'add' });
                              },
                            },
                          ]
                    }
                    disabled={['add', 'edit'].includes(mode)}
                    variant="contained"
                  />
                )}
              </Box>
              {!hideExport && (
                <Box sx={{ ml: 1 }}>
                  {customExport ? (
                    <>
                      {/* Used to trigger a callback in the partent component with any custom export logic */}
                      <Button
                        onClick={() => customExportCallback()}
                        variant="outlined"
                      >
                        Export
                      </Button>
                    </>
                  ) : (
                    <SplitButton
                      startIcon={<Download />}
                      options={
                        exportOptions.length > 0
                          ? exportOptions.map((e) => ({
                              ...e,
                              onClick: () => onExport(e.options),
                              key: e.id,
                            }))
                          : [
                              {
                                id: 'export',
                                label: 'Export',
                                onClick: onExport,
                                key: 'export',
                              },
                            ]
                      }
                      disabled={isDownloading}
                    />
                  )}
                </Box>
              )}
            </Box>
          </Box>
        )}
      </Box>
      <Box>
        {(embed
          ? ['add', 'embed-edit'].includes(embedMode)
          : ['add', 'edit'].includes(mode)) || formModeOnly ? (
          <Box
            sx={{
              overflowY: embed ? 'auto' : 'scroll',
              height: embed
                ? 'inherit'
                : `calc(100vh - ${(headingOffset ?? 0) + 74}px)`,
              display: 'flex',
              justifyContent: 'center',
              py: embed ? 0 : 2,
              px: 2,
            }}
          >
            {DataFormer}
          </Box>
        ) : mode === 'bulkAdd' ? (
          <Box
            sx={{
              overflowY: 'scroll',
              height: embed ? 'inherit' : 'calc(100vh - 168px)',
              display: 'flex',
              justifyContent: 'center',
              px: 2,
              pb: 2,
            }}
          >
            {dataDesc.label !== 'Agent commission schedule profiles'
              ? DataBulkAdder
              : DataBulkAdderCompGrids}
          </Box>
        ) : isLoading ? (
          <LoadingCircle />
        ) : (
          <>
            <EnhancedTable
              dense
              headers={dataDesc.fields}
              rows={dataFiltered}
              rowKey={rowKey}
              onEdit={
                dataDesc.editable
                  ? (row) => {
                      updateSearchParams(
                        embed
                          ? { em: 'embed-edit' }
                          : { m: 'edit', id: row.str_id }
                      );
                      embed && setEmbededId(row.str_id);
                      setNewData(row);
                      setOldData(JSON.parse(JSON.stringify(row)));
                    }
                  : null
              }
              actionsEnabled={() =>
                Array.isArray(dataDesc.actions) && dataDesc.actions.length > 0
              }
              actions={dataDesc.actions}
              refetch={refetch}
              setSelectedData={setSelectedData}
              onDelete={onDelete}
              onBulkEdit={onBulkEdit}
              customHeaderActions={customHeaderActions}
              paginated={enablePagination}
              headingOffset={headingOffset}
              controlledOrdering={{
                order,
                orderBy,
                setOrder: (e) => {
                  setPage(0);
                  setOrder(e);
                },
                setOrderBy: (e) => {
                  setPage(0);
                  setOrderBy(e);
                },
              }}
              {...(enablePagination
                ? {
                    controlledPagination: {
                      count,
                      page,
                      onPageChange: handleChangePage,
                      rowsPerPage,
                      onRowsPerPageChange: handleChangeRowsPerPage,
                    },
                  }
                : {})}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

DataView.propTypes = {
  dataDesc: PropTypes.object,
  formModeOnly: PropTypes.bool,
  viewOnly: PropTypes.bool,
  hideExport: PropTypes.bool,
  refresh: PropTypes.number,
  extraActions: PropTypes.node,
  dataCallback: PropTypes.func,
  onDelete: PropTypes.oneOfType([PropTypes.func, PropTypes.oneOf([null])]),
  handleCustomExportOptions: PropTypes.func,
};

export default DataView;
