import * as Sentry from '@sentry/react';
import { useContext, useEffect } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  useRouteError,
} from 'react-router-dom';

import TransactionDetailsView from '@/components/accounting/TransactionDetailsView';
import TransactionsView from '@/components/accounting/TransactionsView';
import AccountsView from '@/components/admin/AccountsView';
import AdminCompaniesView from '@/components/admin/AdminCompaniesView';
import AdminDocumentsView from '@/components/admin/AdminDocumentsView';
import AdminFieldsView from '@/components/admin/AdminFieldsView';
import AdminUsersView from '@/components/admin/AdminUsersView';
import AdminMetricsView from '@/components/admin/AdminMetricsView';
import CommissionCalculationView from '@/components/CommissionCalculation';
import CommissionsByAgent from '@/components/commissions/CommissionsByAgent';
import CommissionsDataView from '@/components/CommissionsDataView';
import CompaniesView from '@/components/companies/CompaniesView';
import CompanyProductOptionsView from '@/components/companies/CompanyProductOptionsView';
import CompanyProductsView from '@/components/companies/CompanyProductsView';
import ContactGroupsView from '@/components/contacts/ContactGroupsView';
import ContactsView from '@/components/contacts/ContactsView';
import DashboardView from '@/components/DashboardView';
import DocumentProfileView from '@/components/documents/DocumentProfileView/DocumentProfileView';
import DocumentsView from '@/components/documents/DocumentsView';
import ExtractionsView from '@/components/documents/ExtractionsView';
import ImportsView from '@/components/documents/ImportsView';
import MappingsView from '@/components/documents/MappingsView';
import ProcessorsView from '@/components/documents/ProcessorsView';
import DownloadsPage from '@/components/DownloadsPage/DownloadsPage';
import EmptyState from '@/components/EmptyState';
import InsightsView from '@/components/InsightsView';
import NotFoundPage from '@/components/NotFoundPage';
import ErrorImg from '@/illustrations/error.png';
import PolicyDataView from '@/components/PolicyDataView';
import ReconcilerView from '@/components/ReconcilerView';
import ReconcilersFlowsView from '@/components/reconciliation/ReconcilersFlowsView';
import ReconcilersView from '@/components/reconciliation/ReconcilersView';
import ReconciliationsHistoryView from '@/components/reconciliation/ReconciliationsHistoryView';
import ReconciliationsView from '@/components/ReconciliationsView';
import ReportsGroupView from '@/components/ReportsGroupView';
import ReportsGroupDetailsView from '@/components/ReportsGroupView/ReportsGroupDetailsView';
import ReportsView from '@/components/ReportsView';
import SnapshotReport from '@/components/ReportsView/SnapshotReport';
import CommissionSchedulesView from '@/components/schedules/CommissionSchedulesView';
import CompGridCriteriaView from '@/components/schedules/compGrids/CompGridCriteriaView';
import CompGridLevelsView from '@/components/schedules/compGrids/CompGridLevelsView';
import CompGridProductsView from '@/components/schedules/compGrids/CompGridProductsView';
import CompGridRatesView from '@/components/schedules/compGrids/CompGridRatesView';
import CompGridsView from '@/components/schedules/compGrids/CompGridsView';
import CompGridViewerView from '@/components/schedules/compGrids/CompGridViewerView';
import CompProfileSetsView from '@/components/schedules/CompProfileSets/CompProfileSetsView';
import CompProfilesView from '@/components/schedules/CompProfilesView';
import IncentiveTiersView from '@/components/schedules/IncentiveTiersView';
import SettingsView from '@/components/SettingsView/SettingsView';
import ToolsPage from '@/components/ToolsPage';
import Transactions from '@/components/Transactions';
import ViewsView from '@/components/ViewsView';
import CheckView from '@/components/ViewsView/CheckView';
import { LoadingContext } from '@/contexts/LoadingContext';
import { ReactComponent as ErrorIllustration } from '@/illustrations/error.svg';
import API from '@/services/API';
import { useRoleStore } from '@/store';
import Layout from '@/views/Layout';
import PromptsView from '@/views/Prompts';
import Vertex from '@/views/Vertex';
import PoliciesInsights from '@/components/DashboardView/PoliciesInsightsView';
import AgentsInsights from '@/components/DashboardView/AgentsInsightsView';
import AgentGroupInsights from '@/components/DashboardView/AgentGroupInsightsView';
import BusinessInsights from '@/components/DashboardView/BusinessInsightsView';

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const SentryRouteErrorFallback = () => {
  const routeError = useRouteError();

  useEffect(() => {
    Sentry.captureException(routeError, { level: 'fatal' });
  }, [routeError]);

  useEffect(() => {
    if (routeError) {
      Sentry.showReportDialog({
        title: 'Page Navigation Error',
        labelSubmit: 'Submit Report',
        onClose: () => {
          window.location.href = '/';
        },
      });
    }
  }, [routeError]);

  return (
    <EmptyState image={<ErrorIllustration />} title="Something went wrong" />
  );
};

const RoleRequiredPage = (props: {
  children: JSX.Element;
  pageKey: string;
}) => {
  const { children, pageKey } = props;
  const { userRole } = useRoleStore();
  const { setLoadingConfig } = useContext(LoadingContext);
  const { data, isLoading } = API.getBasicQuery(
    'accounts/settings/views-and-fields'
  );
  useEffect(() => {
    setLoadingConfig({ loading: isLoading });
  }, [isLoading]);
  if (isLoading) {
    return null;
  }

  const config = data?.data?.find(
    (r) => r.show_page && r.role === userRole && r.key === pageKey
  );

  if (!config) {
    return <Navigate to="/unauthorized" replace />;
  }
  return children;
};
const RouterComp = ({
  user,
  userState,
  openSnackbar,
  bar,
}: {
  user: any;
  userState: any;
  openSnackbar: () => any;
  roles: string[];
  bar: React.ReactNode;
}) => {
  const Router = sentryCreateBrowserRouter(
    createRoutesFromElements(
      <Route
        path="/"
        element={
          <Layout
            Header={bar}
            user={user}
            userState={userState}
            openSnackbar={openSnackbar}
          />
        }
        errorElement={<SentryRouteErrorFallback />}
      >
        <Route path="/accounting/transactions" element={<TransactionsView />} />
        <Route
          path="/accounting/transaction-details"
          element={<TransactionDetailsView />}
        />
        <Route path="/admin/accounts" element={<AccountsView />} />
        <Route path="/admin/companies" element={<AdminCompaniesView />} />
        <Route path="/admin/documents" element={<AdminDocumentsView />} />
        <Route
          path="/admin/calculation"
          element={<CommissionCalculationView />}
        />
        <Route path="/admin/metrics" element={<AdminMetricsView />} />
        <Route path="/admin/fields" element={<AdminFieldsView />} />
        <Route path="/admin/tools/:tab" element={<ToolsPage />} />
        <Route path="/admin/tools" element={<ToolsPage />} />
        <Route path="/admin/users" element={<AdminUsersView />} />
        <Route path="/reconciler" element={<ReconcilerView />} />
        <Route
          path="/insights"
          element={
            <RoleRequiredPage pageKey="insights">
              <DashboardView dashboardName={null} widgetsFilter={null} />
            </RoleRequiredPage>
          }
        />
        <Route
          path="/insights/business-insights"
          element={
            <RoleRequiredPage pageKey="insights">
              <BusinessInsights />
            </RoleRequiredPage>
          }
        />
        <Route
          path="/insights/policies-insights"
          element={
            <RoleRequiredPage pageKey="insights">
              <PoliciesInsights />
            </RoleRequiredPage>
          }
        />
        <Route
          path="/insights/agents-insights"
          element={
            <RoleRequiredPage pageKey="insights">
              <AgentsInsights />
            </RoleRequiredPage>
          }
        />
        <Route
          path="/insights/agent-group-insights"
          element={
            <RoleRequiredPage pageKey="insights">
              <AgentGroupInsights />
            </RoleRequiredPage>
          }
        />
        <Route path="/dashboard" element={<InsightsView />} />
        <Route path="/transactions" element={<Transactions />} />
        <Route path="/contacts" element={<ContactsView />} />
        <Route
          path="/companies"
          element={
            <RoleRequiredPage pageKey="companies">
              <CompaniesView />
            </RoleRequiredPage>
          }
        />
        <Route path="/companies/products" element={<CompanyProductsView />} />
        <Route
          path="/companies/products/options"
          element={
            <RoleRequiredPage pageKey="options">
              <CompanyProductOptionsView />
            </RoleRequiredPage>
          }
        />
        <Route
          path="/commissions"
          element={
            <RoleRequiredPage pageKey="commissions">
              <CommissionsDataView />
            </RoleRequiredPage>
          }
        />
        <Route
          path="/reconciliation"
          element={
            <RoleRequiredPage pageKey="reconciliation">
              <ReconciliationsView />
            </RoleRequiredPage>
          }
        />
        {/* Deprecated */}
        <Route
          path="/commissions/schedules"
          element={<CommissionSchedulesView />}
        />
        <Route path="/commissions/agent" element={<CommissionsByAgent />} />
        {/* Deprecated */}
        <Route
          path="/commissions/agent-schedule-profiles"
          element={<CompProfilesView />}
        />
        <Route
          path="/agents/list"
          element={
            <RoleRequiredPage pageKey="agents">
              <ContactsView />
            </RoleRequiredPage>
          }
        />
        <Route
          path="/agents/groups"
          element={
            <RoleRequiredPage pageKey="agents_groups">
              <ContactGroupsView />
            </RoleRequiredPage>
          }
        />
        <Route
          path="/agents/production"
          element={
            <RoleRequiredPage pageKey="agents_production">
              <CommissionsByAgent />
            </RoleRequiredPage>
          }
        />
        <Route path="/mappings" element={<MappingsView />} />
        <Route path="/imports" element={<ImportsView />} />
        <Route path="/extractions" element={<ExtractionsView />} />
        <Route path="/documents/profiles" element={<DocumentProfileView />} />
        <Route
          path="/policies"
          element={
            <RoleRequiredPage pageKey="policies">
              <PolicyDataView />
            </RoleRequiredPage>
          }
        />
        <Route
          path="/processors"
          element={<ProcessorsView openSnackbar={openSnackbar} />}
        />
        <Route path="/vertex" element={<Vertex />} />
        <Route path="/prompts" element={<PromptsView />} />
        <Route
          path="/reconciliation/reconcilers"
          element={<ReconcilersView />}
        />
        <Route
          path="/reconciliation/flows"
          element={<ReconcilersFlowsView />}
        />
        <Route
          path="/reconciliation/history"
          element={<ReconciliationsHistoryView />}
        />
        <Route
          path="/reports/summary/:id"
          element={<ReportsGroupDetailsView />}
        />
        <Route
          path="/reports/summary"
          element={
            <RoleRequiredPage pageKey="summaries">
              <ReportsGroupView />
            </RoleRequiredPage>
          }
        />
        <Route
          path="/reports"
          element={
            <RoleRequiredPage pageKey="reports">
              <ReportsView />
            </RoleRequiredPage>
          }
        />
        <Route path="/reports/:id" element={<SnapshotReport />} />
        <Route path="/schedules/comp-profiles" element={<CompProfilesView />} />
        <Route
          path="/schedules/comp-profile-sets"
          element={<CompProfileSetsView />}
        />
        <Route
          path="/schedules/carriers"
          element={
            <RoleRequiredPage pageKey="carriers_schedules">
              <CommissionSchedulesView />
            </RoleRequiredPage>
          }
        />
        <Route
          path="/schedules/comp-grids"
          element={
            <RoleRequiredPage pageKey="comp_grids_schedules">
              <CompGridsView />
            </RoleRequiredPage>
          }
        />
        <Route
          path="/schedules/comp-grids/criteria"
          element={<CompGridCriteriaView />}
        />
        <Route
          path="/schedules/comp-grids/levels"
          element={<CompGridLevelsView />}
        />
        <Route
          path="/schedules/comp-grids/products"
          element={<CompGridProductsView />}
        />
        <Route
          path="/schedules/comp-grids/rates"
          element={<CompGridRatesView />}
        />
        <Route
          path="/schedules/comp-grids/viewer"
          element={<CompGridViewerView />}
        />
        <Route
          path="/schedules/incentive-tiers"
          element={
            <RoleRequiredPage pageKey="incentives_schedules">
              <IncentiveTiersView />
            </RoleRequiredPage>
          }
        />
        <Route
          path="/views"
          element={
            <RoleRequiredPage pageKey="views">
              <ViewsView />
            </RoleRequiredPage>
          }
        />
        <Route path="/views/:id" element={<CheckView />} />
        <Route
          path="/documents"
          element={
            <RoleRequiredPage pageKey="documents">
              <DocumentsView />
            </RoleRequiredPage>
          }
        />
        <Route path="/settings/:tab" element={<SettingsView />} />
        <Route path="/settings" element={<SettingsView />} />
        <Route path="/downloads" element={<DownloadsPage />} />
        <Route
          path="/unauthorized"
          element={
            <EmptyState
              title={'Unauthorized'}
              image={<img src={ErrorImg} width="100%" />}
              description={'You do not have permission to access this page.'}
              size="large"
            />
          }
        />
        <Route path="/" element={<Navigate to={'/reconciliation'} />} />
        <Route element={<NotFoundPage />} />
      </Route>
    ),
    {
      basename: process.env.REACT_APP_BASENAME || '/',
    }
  );

  return Router;
};

export default RouterComp;
