import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Chip,
  Tooltip,
  Box,
  Button,
  TableCell,
  TableRow,
  Grid,
  IconButton,
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';

const CardView = ({
  row,
  headersFiltered,
  isItemSelected,
  handleClick,
  onEdit,
  readOnly,
  onDelete,
  onClick,
  outstandingFieldsInMobileView,
  dynamicSelects,
  navigate,
  key,
  nonSelectable,
}) => {
  const formatter = (x, dynamicSelects, header) => {
    if (header?.tableFormatter instanceof Function) {
      return header.tableFormatter(x, row, dynamicSelects, header);
    }
    if (header?.formatter instanceof Function) {
      if (dynamicSelects) {
        if (!x) return null;
        if (React.isValidElement(x)) return x;
        if (React.isValidElement(header?.formatter(x, dynamicSelects)))
          return header?.formatter(x, dynamicSelects);
        return (
          <Chip label={header?.formatter(x, dynamicSelects)} sx={{ m: 0.25 }} />
        );
      }
      if (x instanceof Object) {
        const res = header?.formatter(x, row, navigate);
        return typeof res === 'object' && !React.isValidElement(res)
          ? '---'
          : res;
      }
      return header?.formatter(x, row, navigate);
    }
    if (x instanceof Object && !React.isValidElement(x)) {
      return JSON.stringify(x);
    }
    if (header?.type === 'boolean') {
      return x ? 'Yes' : 'No';
    }
    return x;
  };

  const handleCardClick = (event) => {
    if (nonSelectable) return;
    if (onDelete instanceof Function) {
      handleClick(event, row.id || Object.values(row).join('-'));
    } else if (onClick instanceof Function && !onDelete) {
      onClick(row);
    }
  };

  const SelectDbValsShowMore = ({ data }) => {
    const [showMore, setShowMore] = useState(false);
    return (
      <Box>
        {data.slice(0, 8).map((item, i) => (
          <Box key={i}>{item}</Box>
        ))}
        {showMore && data.slice(8).map((item, i) => <Box key={i}>{item}</Box>)}
        {data.length > 8 && (
          <Button onClick={() => setShowMore(!showMore)} sx={{ color: '#555' }}>
            {showMore ? 'Show less' : `Show ${data.length - 8} more`}
          </Button>
        )}
      </Box>
    );
  };
  const [collapsed, setCollapsed] = useState(true);
  const toggleCollaps = (e) => {
    e.stopPropagation();
    setCollapsed(!collapsed);
  };
  const renderTableCell = (row, headerKey, header, getter) => {
    row[headerKey] !== undefined;
    return Array.isArray(row[header.id]) && !header.tableFormatter ? (
      header.id === 'notes' ? (
        <Tooltip
          title={
            <Box>
              {row[header.id].map((item) => (
                <Box key={item} sx={{ my: 0.5 }}>
                  {item}
                </Box>
              ))}
            </Box>
          }
          arrow
        >
          <span style={{ whiteSpace: 'nowrap' }}>
            {row[header.id].length
              ? `⚠️ ${row[header.id].length} conflicts`
              : null}
          </span>
        </Tooltip>
      ) : (
        <SelectDbValsShowMore
          data={row[header.id].map((item, i) => (
            <span key={typeof item === 'object' ? JSON.stringify(item) : item}>
              {header.type === 'dynamic-select' ? (
                formatter(
                  item,
                  dynamicSelects[header.table]?.data ??
                    dynamicSelects[header.table],
                  header
                )
              ) : (
                <Chip
                  key={item}
                  label={formatter(
                    item,
                    dynamicSelects[header.table]?.data ??
                      dynamicSelects[header.table],
                    header
                  )}
                  sx={{
                    m: 0.25,
                    cursor:
                      header.itemOnClick || header.linker
                        ? 'pointer'
                        : 'default',
                    maxWidth: 500,
                    '&.MuiChip-root': {
                      height: 'auto',
                      '& .MuiChip-root': {
                        height: 'auto',
                      },
                    },
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    typeof header.itemOnClick === 'function' &&
                      header.itemOnClick(item);
                  }}
                  clickable={typeof header.linker === 'function'}
                  component={typeof header.linker === 'function' ? 'a' : 'div'}
                  href={
                    typeof header.linker === 'function'
                      ? header.linker(item)
                      : undefined
                  }
                />
              )}
              {header.delimiter &&
                i < row[header.id].length - 1 &&
                header.delimiter}
            </span>
          ))}
        />
      )
    ) : (
      formatter(
        getter(row),
        header.table
          ? (dynamicSelects[header.table]?.data ?? dynamicSelects[header.table])
          : undefined,
        header
      )
    );
    return null;
  };

  const displayItem = (headerKey) => {
    if (
      !outstandingFieldsInMobileView ||
      outstandingFieldsInMobileView.length === 0
    ) {
      return true;
    }
    if (!collapsed) {
      return true;
    }
    return outstandingFieldsInMobileView.includes(headerKey);
  };

  return (
    <TableRow key={key} className="TableRowCard">
      <TableCell sx={{ px: 1 }}>
        <Card
          onClick={handleCardClick}
          sx={
            isItemSelected
              ? { border: '1px solid #2196f3', background: '#e5f1fd' }
              : { border: 'inherit' }
          }
        >
          <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }}>
            {/* {onDelete &&
              (options.radio ? (
                <Radio checked={isItemSelected} />
              ) : (
                <Checkbox
                  color="primary"
                  checked={isItemSelected}
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              ))} */}
            <Grid container spacing={1}>
              {headersFiltered?.map((header) => {
                const headerKey = header.keyAs ?? header.id;
                const getter = header.getter || ((x) => x[headerKey]);
                return (
                  <Grid
                    item
                    xs={6}
                    key={`${header.source ?? ''}${header.id}${header.id2 ?? ''}`}
                    sx={{
                      display:
                        displayItem(headerKey) &&
                        renderTableCell(row, headerKey, header, getter)
                          ? 'inherit'
                          : 'none',
                    }}
                  >
                    <Box
                      sx={{
                        lineBreak: 'anywhere',
                        fontSize: '16px',
                        fontWeight: 500,
                      }}
                    >
                      {renderTableCell(row, headerKey, header, getter) && (
                        <Typography
                          variant="subtitle1"
                          sx={{
                            fontSize: '11px',
                            color: '#555',
                            mb: -0.5,
                          }}
                        >
                          {header.label}
                        </Typography>
                      )}
                      {renderTableCell(row, headerKey, header, getter)}
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              {onEdit instanceof Function && !readOnly && (
                <IconButton
                  onClick={() => {
                    onEdit(row);
                  }}
                  sx={{ mr: 0.5 }}
                >
                  <EditIcon />
                </IconButton>
              )}
              {outstandingFieldsInMobileView &&
                outstandingFieldsInMobileView.length > 0 && (
                  <IconButton onClick={toggleCollaps}>
                    {collapsed ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
                  </IconButton>
                )}
            </Box>
          </CardContent>
        </Card>
      </TableCell>
    </TableRow>
  );
};

export default CardView;
