import { LoadingButton } from '@mui/lab';
import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import dayjs from 'dayjs';
import { dateOrDefault } from 'common/helpers';

import API from '@/services/API';
import BasicDateRangePicker from '@/common/BasicDateRangePicker';
import MultiSelect from '../molecules/MultiSelect';
import Formatter from '@/services/Formatter';

const DataUpdateTools: React.FC = () => {
  const [loading, setloading] = useState(false);
  const [startDate, setStartDate] = useState<dayjs.Dayjs | string | null>(null);
  const [endDate, setEndDate] = useState<dayjs.Dayjs | string | null>(null);
  const [selectedDocs, setSelectedDocs] = useState<string[]>([]);

  const { data: _documents } = API.getBasicQuery(
    'documents',
    'is_dynamic_select=true&limit=300'
  );

  const runCompensationTypePoster = API.getMutation(
    'admin/post-reconciliation/compensation-type',
    'POST'
  );

  const { openSnackbar } = useOutletContext() as any;

  const documents = _documents?.data ?? [];

  return (
    <Box>
      <Box>
        <Typography sx={{ mt: 0.5 }}>
          Commissions: Compensation type assignment
        </Typography>
        <Box sx={{ mt: 1, mb: 1 }}>
          <BasicDateRangePicker
            range={{
              startDate: startDate,
              startDateLabel: 'Processing date start',
              endDate: endDate,
              endDateLabel: 'Processing date end',
            }}
            onChange={(range) => {
              setStartDate(range.startDate);
              setEndDate(range.endDate);
            }}
            justify="left"
            width={210}
          />
          <MultiSelect<any, any>
            label="Document filter"
            values={documents}
            valuer={(o) => o.str_id}
            formatter={(o) => {
              return o
                ? `${o?.filename} (${Formatter.date(o?.created_at, { format: 'YYYY/MM/DD hh:mmA' })})`
                : '';
            }}
            selectedValues={selectedDocs}
            setSelectedValues={(values) =>
              setSelectedDocs(values.filter((value) => value !== ''))
            }
            sx={{ width: 200, mt: 1.5 }}
            enableSearch
            paginate
          />
        </Box>
        <LoadingButton
          loading={loading}
          variant="contained"
          onClick={async () => {
            setloading(true);
            const params = {
              processing_date_start: dateOrDefault(startDate, undefined),
              processing_date_end: dateOrDefault(endDate, undefined),
              document_str_ids: selectedDocs,
            };

            const response =
              await runCompensationTypePoster.mutateAsync(params);
            if (response.error) {
              openSnackbar(response.error);
            } else {
              openSnackbar(
                `Compensation type updated for ${response.data} commissions line items.`
              );
            }
            setloading(false);
          }}
          sx={{ mr: 1 }}
        >
          Update
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default DataUpdateTools;
